.navbar {
    position: absolute;
    top: 0;
    background-color: transparent;
    height: 70px;
    width: 100%;
    color: azure;
    font-size: 2rem;
    z-index: 100;
}

.hamburger {
    margin: 25px;
}
